class Helper{
    rounded=(num)=>{
        return Math.round((num + Number.EPSILON) * 100) / 100
    }
    calcChunk=(data)=>{
        return (data * 1024 * 1024 / 8) / 4;
    }
    randomBytes=(size)=> {
        let str = '';
        for (let i = 0; i < size; i++) {
            let randByte = parseInt(Math.random() * 256, 10);
            randByte = randByte.toString(16);
            if (randByte.length===1) {
                randByte = "0" + randByte;
            }
            str += randByte;
        }
        return str;
    }
}

export const {rounded, randomBytes, calcChunk} = new Helper();