import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import {useSelector} from "react-redux";
const DownloadSpeedMeter = () => {
    let PSpeed=useSelector((state)=>(state.ping.PSpeed));
    return (
        <div>
            <ReactSpeedometer
                minValue={0}
                maxValue={3000}
                startColor={'#232F4E'}
                endColor={'#0D6EFD'}
                value={PSpeed}
                textColor={"#FFFFFF"}
                labelFontSize={"11px"}
                needleColor={"#0D6EFD"}
                needleHeightRatio={0.8}
                currentValueText={PSpeed + " ms"}
                valueTextFontSize={"13px"}
                valueTextFontWeight={"100"}
                width={250}
                height={190}
                ringWidth={15}
                needleTransitionDuration={100}
                needleTransition="easeElastic"
            />
        </div>
    );
};
export default DownloadSpeedMeter;