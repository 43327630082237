import {createSlice} from "@reduxjs/toolkit";
export const pingSlice=createSlice({
    name:'ping',
    initialState:{
        PSpeed:0
    },
    reducers:{
        SetPSpeed:(state,action)=>{
            state.PSpeed=action.payload
        }
    }
})
export  const {SetPSpeed}=pingSlice.actions;
export default  pingSlice.reducer;
