import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import {useSelector} from "react-redux";
const UploadSpeedMeter = () => {
    let USpeed=useSelector((state)=>(state.upload.USpeed));
    return (
        <div>
            <ReactSpeedometer
                minValue={0}
                maxValue={1000}
                startColor={'#232F4E'}
                endColor={'#EA4335'}
                value={USpeed}
                textColor={"#FFFFFF"}
                labelFontSize={"11px"}
                needleColor={"#EA4335"}
                needleHeightRatio={0.8}
                currentValueText={USpeed + " Mbps"}
                valueTextFontSize={"13px"}
                valueTextFontWeight={"100"}
                width={250}
                height={190}
                ringWidth={15}
                needleTransitionDuration={100}
                needleTransition="easeElastic"
            />
        </div>
    );
};
export default UploadSpeedMeter;