import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import {useSelector} from "react-redux";
const DownloadSpeedMeter = () => {

    let DSpeed=useSelector((state)=>(state.download.DSpeed));

    return (
        <div>
            <div>
                <ReactSpeedometer
                    minValue={0}
                    maxValue={1000}
                    startColor={'#232F4E'}
                    endColor={'#21BF73'}
                    value={DSpeed}
                    textColor={"#FFFFFF"}
                    labelFontSize={"11px"}
                    needleColor={"#21BF73"}
                    needleHeightRatio={0.8}
                    currentValueText={DSpeed + " Mbps"}
                    valueTextFontSize={"13px"}
                    valueTextFontWeight={"100"}
                    width={250}
                    height={190}
                    ringWidth={15}
                    needleTransitionDuration={100}
                    needleTransition="easeElastic"
                />
            </div>
        </div>
    );
};
export default DownloadSpeedMeter;