import {createSlice} from "@reduxjs/toolkit";
export const downloadSlice=createSlice({
    name:'download',
    initialState:{
        DSpeed:0,
    },
    reducers:{
        SetDSpeed:(state,action)=>{
            state.DSpeed=action.payload
        }
    }
})
export  const {SetDSpeed}=downloadSlice.actions;
export default  downloadSlice.reducer;
