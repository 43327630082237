import {createSlice} from "@reduxjs/toolkit";
export const uploadSlice=createSlice({
    name:'upload',
    initialState:{
        USpeed:0,
    },
    reducers:{
        SetUSpeed:(state,action)=>{
            state.USpeed=action.payload
        }
    }
})
export  const {SetUSpeed}=uploadSlice.actions;
export default  uploadSlice.reducer;
