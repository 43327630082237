import {configureStore} from "@reduxjs/toolkit";
import downloadReducer from "../state-slice/download-slice";
import uploadReducer from "../state-slice/upload-slice";
import pingReducer from "../state-slice/ping-slice";
export default configureStore({
    reducer:{
        ping:pingReducer,
        download:downloadReducer,
        upload:uploadReducer
    }
})
