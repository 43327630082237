import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import io from 'socket.io-client';
import {calcChunk, randomBytes, rounded} from "../helper/Helper";
import store from "../redux/store/store";
import {SetDSpeed} from "../redux/state-slice/download-slice";
import DownloadSpeedMeter from "../component/DownloadSpeedMeter";
import {SetUSpeed} from "../redux/state-slice/upload-slice";
import UploadSpeedMeter from "../component/UploadSpeedMeter";
import {SetPSpeed} from "../redux/state-slice/ping-slice";
import PingSpeedMeter from "../component/PingSpeedMeter";

const socket=io.connect('/');

const App = () => {

    let results = [];
    let requestStart;
    let testStart;
    let startChunkSize = 100000; // 1 KiB
    let chunkSize;



    // Ping Latency Calculation
    const StartPing=()=>{
        document.getElementById('startBtn').innerHTML="Processing..."
        document.getElementById('startBtn').classList.add("btn-warning")
        document.getElementById('startBtn').disabled=true;
        results = [];
        ping();
    }
    const ping=()=>{
        setTimeout(()=> {
            socket.emit("clientPing", Date.now())
        },50)
    }
    socket.on('serverPong', function(data) {
        let latency = Date.now() - data;
        results.push(latency);
        store.dispatch(SetPSpeed(rounded(latency)));
        if (results.length === 15){
            let min = Math.min.apply(null, results);
            store.dispatch(SetPSpeed(rounded(min)))
            StartDownload();
        }else{
            ping();
        }
    });


    // Download Speed Calculation
    const StartDownload=()=>{
        results = [];
        chunkSize = startChunkSize;
        testStart = Date.now();
        download();
    }
    const download=()=>{
        setTimeout(function() {
            requestStart = Date.now();
            socket.emit('download', chunkSize);
        }, 10)
    }
    socket.on('download', function(data) {
        let elapsed = ( Date.now() - requestStart ) / 1000; //in sec
        let received = data.byteLength * 8 / 1024 / 1024;
        let result = received / elapsed;
        results.push(result);
        store.dispatch(SetDSpeed(rounded(result)));
        if(Date.now() - testStart > 1000 * 10){
            let max = Math.max.apply(null, results);
            store.dispatch(SetDSpeed(rounded(max)));
            StartUpload()
        }else{
            chunkSize = calcChunk(result);
            download();
        }
    })



    // Upload Speed Calculation
    const StartUpload=()=>{
        results = [];
        chunkSize = startChunkSize;
        testStart = Date.now();
        upload();
    }
    const upload=()=>{
        setTimeout(function () {
            let data = randomBytes(chunkSize);
            requestStart = Date.now();
            socket.emit('upload', data);
        }, 10)
    }
    socket.on('upload', ()=> {
        let elapsed = ( Date.now() - requestStart ) / 1000; //in sec
        let sent = chunkSize * 8 / 1024 / 1024;
        let result = sent / elapsed;
        results.push(result);
        store.dispatch(SetUSpeed(rounded(result)));
        if(Date.now() - testStart > 1000 * 10){
            let max = Math.max.apply(null, results);
            store.dispatch(SetUSpeed(rounded(max)));
            document.getElementById('startBtn').innerHTML="Test Again"
            document.getElementById('startBtn').classList.remove("btn-warning")
            document.getElementById('startBtn').disabled=false;
            toast.success("Test Completed")
        }else{
            upload();
        }
    })


    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 col-sm-12 col-lg-10 col-12">
                    <div className="container-fluid">

                        <div className="row my-5 ">
                            <div className="col-md-12 text-center p-2">
                                <h2 className="text-white">Test Your Internet Speed</h2>
                                <p className="text-light">Find out how fast your internet connection, measure ping time in millisecond , maximum download and upload speed in Megabits per second (Mbps) unit </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 text-center p-2">
                                <PingSpeedMeter/>
                                <h6 className="text-white">Ping Speed</h6>
                            </div>
                            <div className="col-md-4 text-center p-2">
                                <DownloadSpeedMeter/>
                                <h6 className="text-white">Download Speed</h6>
                            </div>
                            <div className="col-md-4 text-center p-2">
                                <UploadSpeedMeter/>
                                <h6 className="text-white">Upload Speed</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center p-2">
                                <button id="startBtn" className="btn my-5 px-5 btn-primary" onClick={StartPing}>Start Testing</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="bottom-center"/>
        </div>
    );


};
export default App;