import React from 'react';
import ReactDOM from 'react-dom';
import App from './page/App';
import {Provider} from "react-redux";
import store from "./redux/store/store";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
